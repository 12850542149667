import React, { useCallback, useEffect, useState } from "react";
import useCollapse from "react-collapsed";

import IconSvg from "../../../../../shared/components/IconSvg";

import arrowIcon from "../../../../../shared/assets/icon-dash-arrow.svg";
import arrowIconDown from "../../../../../shared/assets/icon-arrowdown-dash.svg";

import {
  CollapseList,
  Container,
  FlexDiv,
  TitleDisabled,
  TitleActive,
} from "./styles";

interface IDropdownProps {
  name: string;
  icon: string;
  iconClick: string;
  expanded: boolean;
}

const DropdownBitsClause: React.FC<IDropdownProps> = ({
  children,
  name,
  icon,
  iconClick,
  expanded,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });

  const handleClickCollapse = useCallback((value) => {
    setIsExpanded(value);
  }, []);

  useEffect(() => {
    handleClickCollapse(expanded);
  }, [expanded, handleClickCollapse]);

  return (
    <Container isExpanded={isExpanded}>
      <div
        style={{ cursor: "pointer" }}
        className="header-collapse"
        {...getToggleProps({ onClick: () => handleClickCollapse(!isExpanded) })}
      >
        {isExpanded ? (
          <>
            <FlexDiv>
              <IconSvg image={iconClick} width="21px" height="16px" />
              <TitleActive>{name}</TitleActive>
            </FlexDiv>

            <IconSvg image={arrowIcon} width="12px" height="15px" />
          </>
        ) : (
          <>
            <FlexDiv>
              <IconSvg image={icon} width="21px" height="16px" />
              <TitleDisabled>{name}</TitleDisabled>
            </FlexDiv>

            <IconSvg image={arrowIconDown} width="12px" height="15px" />
          </>
        )}
      </div>
      <div className="content-collapse" {...getCollapseProps()}>
        <CollapseList>{children}</CollapseList>
      </div>
    </Container>
  );
};

export default DropdownBitsClause;

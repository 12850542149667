import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../../services/api";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SyncLoader from "react-spinners/SyncLoader";
import * as Dialog from "@radix-ui/react-dialog";
import { format } from "date-fns";
import { useAuth } from "../../../../hooks/auth";
import { saveAs } from "file-saver";

import IHookForm from "../../../../shared/dtos/IHookForm";
import IListOrganizationFilesApiProps from "./dtos/IListOrganizationFilesApiProps";
import IListOrganizationFilesDataParams from "./dtos/IListOrganizationFilesDataParams";
import IFormSearch from "./dtos/IFormSearch";

import MasterAsideControls from "../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../components/SidebarMaster/MasterMain";

import DialogModal from "../../../../shared/components/DialogModal";
import Modal from "../../../../shared/components/Modal";
import Button from "../../../../shared/components/Button";
import Input from "../../../../shared/components/Input";
import IconSvg from "../../../../shared/components/IconSvg";
import Pagination from "../../../../shared/components/Pagination";
import Tooltip from "../../../../shared/components/Tooltip";

import trashIcon from "../../../../shared/assets/trash-icon.svg";
import iconSearchWhite from "../../../../shared/assets/icon-search-white.svg";

import { Notify } from "../../../../shared/utils";

import {
  BoxList,
  ButtonFlexBox,
  ButtonSearch,
  FlexBox,
  HStack,
  Main,
  Form,
  ContainerCreate,
  FlexPagination,
  ContainerLoading,
  Table,
  TableTh,
  TableTr,
  TableTd,
  TableThead,
  TableTbody,
  TableTdText,
  FileUploadContainer,
  FileUploadInput,
  FileUploadLabel,
  TitleModal,
  SubtitleModal,
  ContainerLoadingModal,
  PdfLink,
} from "./styles";

const Support: React.FC = () => {
  const { data: dataUseAuth } = useAuth();

  const [modal, setModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [dataOrganizationFilesList, setDataOrganizationFilesList] =
    useState<IListOrganizationFilesApiProps>({
      pagination: 0,
      dataArray: [],
    });
  const [listOrganizationFilesDataParams, setListOrganizationFilesDataParams] =
    useState<IListOrganizationFilesDataParams>({
      pagination: 0,
      organization_id: dataUseAuth.organization.id,
    });

  useEffect(() => {
    (async () => {
      const response = await api.get(`/organization-files`, {
        params: listOrganizationFilesDataParams,
      });

      setDataOrganizationFilesList(response.data);
      setTotalPages(response.data.pagination);
      setLoading(false);
    })();
  }, [reload, listOrganizationFilesDataParams, dataUseAuth]);

  const handleDeleteOrganizationFile = useCallback(
    async (id: string) => {
      await api.delete(`/organization-files/${id}`);

      Notify({ text: "Arquivo PDF deletado com sucesso!", type: "success" });

      setReload(!reload);
    },
    [reload]
  );

  const formSchema = yup.object().shape({
    search: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<IFormSearch> = useCallback(async (formData) => {
    try {
      setLoading(true);
      if (formData.search === "") {
        setListOrganizationFilesDataParams({
          pagination: 0,
          organization_id: dataUseAuth.organization.id,
        });
      } else {
        setListOrganizationFilesDataParams({
          pagination: 0,
          search: formData.search,
          organization_id: dataUseAuth.organization.id,
        });
      }
    } catch (err: any) {
      console.log(err?.response?.data);
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setModal(false);
    setSelectedFile(null);
  }, []);

  const handleOpenModal = useCallback(() => {
    setModal(true);
  }, []);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      if (file) {
        setSelectedFile(file);
      }
    },
    []
  );

  const handleUpload = useCallback(async () => {
    if (selectedFile) {
      try {
        setLoadingModal(true);

        const createFile = await api.post(`organization-files`, {
          organization_id: dataUseAuth.organization.id,
          name: selectedFile.name,
        });

        const patch = new FormData();

        patch.append("file", selectedFile);

        const uploadShape = await api.patch(
          `organization-files/${createFile.data.id}`,
          patch,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (uploadShape.status === 200) {
          Notify({
            text: "Arquivo PDF cadastrado com sucesso",
            type: "success",
          });
          setLoadingModal(false);
          handleCloseModal();
          setReload(!reload);
        }
      } catch (error: any) {
        setLoadingModal(false);
        Notify({
          text: "Aconteceu algum erro ao cadastrar o arquivo PDF.",
          type: "error",
        });
      }
    }
  }, [handleCloseModal, selectedFile, dataUseAuth.organization.id, reload]);

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Ouvidoria" />
      <MasterMain>
        <Main>
          <ContainerCreate>
            <Button onClick={handleOpenModal}>Cadastrar PDF</Button>
          </ContainerCreate>
          <HStack>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Input
                label="search"
                register={register}
                required={true}
                placeholder="Pesquisar"
                style={{ width: "98%" }}
              />

              <Tooltip title="Pesquisar" placement="top">
                <ButtonSearch type="submit">
                  <IconSvg image={iconSearchWhite} width="18" height="18" />
                </ButtonSearch>
              </Tooltip>
            </Form>
          </HStack>

          <BoxList>
            {loading && (
              <ContainerLoading>
                <SyncLoader loading={loading} color="#15aabf" size={10} />
              </ContainerLoading>
            )}

            {!loading && (
              <Table>
                <TableThead>
                  <TableTr>
                    <TableTh>Nome</TableTh>
                    <TableTh>Link</TableTh>
                    <TableTh>Criado em</TableTh>
                    <TableTh>Opções</TableTh>
                  </TableTr>
                </TableThead>

                <TableTbody>
                  {dataOrganizationFilesList.dataArray.map((item, index) => (
                    <TableTr key={item.id}>
                      <TableTd>
                        <TableTdText>{item.name}</TableTdText>
                      </TableTd>
                      <TableTd>
                        <TableTdText>
                          <PdfLink href={item.pdf_url} target="_blank">
                            Acesse aqui o PDF
                          </PdfLink>
                        </TableTdText>
                      </TableTd>
                      <TableTd>
                        <TableTdText>
                          {format(
                            new Date(item.created_at),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </TableTdText>
                      </TableTd>

                      <TableTd>
                        <FlexBox>
                          <Dialog.Root>
                            <Tooltip title="Deletar PDF" placement="top">
                              <Dialog.Trigger asChild>
                                <ButtonFlexBox>
                                  <IconSvg
                                    width="18"
                                    height="18"
                                    image={trashIcon}
                                  />
                                </ButtonFlexBox>
                              </Dialog.Trigger>
                            </Tooltip>

                            <DialogModal
                              title="Deletar PDF"
                              subtitle={`${item.name}`}
                              type="delete"
                              callback={() =>
                                handleDeleteOrganizationFile(item.id)
                              }
                            />
                          </Dialog.Root>
                        </FlexBox>
                      </TableTd>
                    </TableTr>
                  ))}
                </TableTbody>
              </Table>
            )}
          </BoxList>
          <FlexPagination>
            {dataOrganizationFilesList.dataArray.length > 0 && (
              <Pagination
                total={totalPages}
                offset={listOrganizationFilesDataParams.pagination}
                setDataParams={setListOrganizationFilesDataParams}
                dataParams={listOrganizationFilesDataParams}
              />
            )}
          </FlexPagination>

          <Modal isOpen={modal} onClose={handleCloseModal}>
            <FileUploadContainer>
              {loadingModal && (
                <ContainerLoadingModal>
                  <SyncLoader
                    loading={loadingModal}
                    color="#15aabf"
                    size={10}
                  />
                </ContainerLoadingModal>
              )}

              {!loadingModal && (
                <>
                  <TitleModal>Cadastrar PDF</TitleModal>

                  <SubtitleModal>
                    Faça upload do seu arquivo PDF no botão abaixo
                  </SubtitleModal>

                  <FileUploadInput
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    id="file-upload"
                  />

                  <FileUploadLabel htmlFor="file-upload">
                    {selectedFile ? selectedFile.name : "Selecionar arquivo"}
                  </FileUploadLabel>

                  {selectedFile && (
                    <Button onClick={handleUpload}>Enviar</Button>
                  )}
                </>
              )}
            </FileUploadContainer>
          </Modal>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default Support;

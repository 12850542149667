import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../Button";

import { Container, Title, Img, Text, SubText } from "./styles";

const InputSelect: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate("/");
  }, []);

  return (
    <Container>
      <Title>404</Title>

      <Img />

      <Text>Parece que você está perdido</Text>
      <SubText>Clique no botão abaixo para voltar a dashboard</SubText>

      <Button onClick={handleGoBack} style={{ marginBottom: 20 }}>
        Voltar para dashboard
      </Button>
    </Container>
  );
};

export default InputSelect;

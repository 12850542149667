import React, {
  HTMLProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink } from "react-router-dom";

import { ButtonNav, TextNav, Container } from "./styles";

import IconSvg from "../../../../../shared/components/IconSvg";

interface ButtonNavigatorProps extends HTMLProps<ButtonNavigatorProps> {
  name: string;
  icon: string;
  iconClick: string;
  isActive?: boolean;
  onClick?: () => void;
  index?: number;
  indexMenu?: number;
  url: string;
}

const ButtonNavigator: React.FC<ButtonNavigatorProps> = ({
  name,
  icon,
  iconClick,
  isActive,
  onClick,
  index,
  indexMenu,
  url,
}) => {
  const [newClass, setNewClass] = useState("inactive-link");

  const refNavLink = useRef<HTMLAnchorElement>();

  useEffect(() => {
    (() => {
      const classNameNavLink = refNavLink.current.className;

      setNewClass(classNameNavLink);
    })();
  }, [refNavLink]);

  return (
    <Container>
      <NavLink
        className={({ isActive }) => {
          return isActive ? "active-link" : "inactive-link";
        }}
        ref={refNavLink}
        to={url}
        end
      >
        <ButtonNav
          onClick={onClick}
          index={index}
          isActive={isActive}
          indexMenu={indexMenu}
        >
          {newClass === "active-link" && (
            <IconSvg image={iconClick} width={"20"} height={"20"} />
          )}
          {newClass === "inactive-link" && (
            <IconSvg image={icon} width={"20"} height={"20"} />
          )}
          <TextNav> {name} </TextNav>
        </ButtonNav>
      </NavLink>
    </Container>
  );
};

export default ButtonNavigator;

import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../../../../../../services/api";
import { Divider } from "@material-ui/core";
import SyncLoader from "react-spinners/SyncLoader";
import { useAuth } from "../../../../../../hooks/auth";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IFormCreateUserCommon from "./dtos/IFormCreateUserCommon";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import Button from "../../../../../../shared/components/Button";
import ButtonCancel from "../../../../../../shared/components/ButtonCancel";
import Input from "../../../../../../shared/components/Input";

import { Notify } from "../../../../../../shared/utils";

import { Main, Form, ContainerInput, LabelInput, ErrorInput } from "./styles";

const CreateUserCommon: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { data: dataUseAuth } = useAuth();

  const navigate = useNavigate();

  const formSchema = yup.object().shape({
    first_name: yup.string().required("nome é um campo obrigatório"),
    last_name: yup.string().required("sobrenome é um campo obrigatório"),
    email: yup
      .string()
      .email("Insira um email valido")
      .required("email é um campo obrigatório"),
    password: yup
      .string()
      .min(8, "A senha deve ter no mínimo 8 caracteres")
      .matches(
        /(?=.*[A-Z])/g,
        "A senha deve conter pelo menos uma letra maiúscula"
      )
      .matches(/(?=.*\d)/g, "A senha deve conter pelo menos um número")
      .matches(/[a-z]/, "Senha deve conter pelo menos uma letra minúscula")
      .matches(
        /[*@!#%$&()^~?:<>{}]+/,
        "A senha deve conter pelo menos um símbolo"
      )
      .required(`Senha necessária`),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    reset();
  }, [reset]);

  const onSubmit: SubmitHandler<IFormCreateUserCommon> = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        const createUser = await api.post("/organization-users", {
          organization_id: dataUseAuth.organization.id,
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          password: formData.password,
          is_admin: false,
        });

        if (createUser.status === 200) {
          Notify({ text: "Usuário cadastrado com sucesso", type: "success" });
          setLoading(false);
          navigate("/users-common");
        }
      } catch (err: any) {
        if (err?.response?.data?.message === "Email address alredy used") {
          Notify({ text: "Email já cadastrado", type: "error" });
        }
        setLoading(false);
      }
    },
    [navigate, dataUseAuth]
  );

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Criar usuário" />
      <MasterMain>
        <Main>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContainerInput>
              <LabelInput>Nome *</LabelInput>
              <Input
                label="first_name"
                register={register}
                required={true}
                placeholder="Digite o nome"
              />
              {errors.first_name && (
                <ErrorInput>{errors.first_name.message}</ErrorInput>
              )}
            </ContainerInput>
            <ContainerInput>
              <LabelInput>Sobrenome *</LabelInput>
              <Input
                label="last_name"
                register={register}
                required={true}
                placeholder="Digite o sobrenome"
              />
              {errors.last_name && (
                <ErrorInput>{errors.last_name.message}</ErrorInput>
              )}
            </ContainerInput>
            <ContainerInput>
              <LabelInput>Email *</LabelInput>
              <Input
                label="email"
                register={register}
                required={true}
                placeholder="Digite o email"
              />
              {errors.email && <ErrorInput>{errors.email.message}</ErrorInput>}
            </ContainerInput>
            <ContainerInput>
              <LabelInput>Senha *</LabelInput>
              <Input
                label="password"
                register={register}
                required={true}
                placeholder="Digite a senha"
              />
              {errors.password && (
                <ErrorInput>{errors.password.message}</ErrorInput>
              )}
            </ContainerInput>

            <Divider style={{ marginBottom: 10, marginTop: 20 }} />

            <ButtonCancel
              onClick={() => {
                navigate("/users-common");
              }}
              type="button"
              style={{ marginTop: 30, marginRight: 10 }}
              disabled={loading}
            >
              Cancelar
            </ButtonCancel>

            {!loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                Cadastrar
              </Button>
            )}

            {loading && (
              <Button type="submit" style={{ marginTop: 30 }}>
                <SyncLoader
                  loading={loading}
                  color="#ffffff"
                  size={5}
                  style={{ marginLeft: 30, marginRight: 30 }}
                />
              </Button>
            )}
          </Form>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default CreateUserCommon;

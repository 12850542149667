import React from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth } from "../hooks/auth";

import Home from "../modules/dashboard/pages/Home";
import CreateUserAdmin from "../modules/dashboard/pages/Users/Admin/CreateUserAdmin";
import ListUsersAdmin from "../modules/dashboard/pages/Users/Admin/ListUsersAdmin";
import ResetPasswordUserAdmin from "../modules/dashboard/pages/Users/Admin/ResetPasswordUserAdmin";
import CreateUserCommon from "../modules/dashboard/pages/Users/Common/CreateUserCommon";
import ListUsersCommon from "../modules/dashboard/pages/Users/Common/ListUsersCommon";
import ResetPasswordUserCommon from "../modules/dashboard/pages/Users/Common/ResetPasswordUserCommon";
import RouteDoesntExist from "../shared/components/RouteDoesntExist";
import Support from "../modules/dashboard/pages/Support";

import Exit from "../modules/login/pages/Exit";
import Login from "../modules/login/pages/Login";

const RouteApp: React.FC = () => {
  const { data: dataUseAuth } = useAuth();

  return dataUseAuth ? (
    <Routes>
      <Route path="/" element={<Home />} />

      {/* User admin */}
      <Route path="/users-admin" element={<ListUsersAdmin />} />
      <Route path="/create-user-admin" element={<CreateUserAdmin />} />
      <Route
        path="/reset-password-user-admin/:user_id"
        element={<ResetPasswordUserAdmin />}
      />

      {/* User common */}
      <Route path="/users-common" element={<ListUsersCommon />} />
      <Route path="/create-user-common" element={<CreateUserCommon />} />
      <Route
        path="/reset-password-user-common/:user_id"
        element={<ResetPasswordUserCommon />}
      />

      {/* ROUTE DOESN'T EXIST */}
      <Route path="/support" element={<Support />} />

      {/* ROUTE DOESN'T EXIST */}
      <Route path="*" element={<RouteDoesntExist />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/exit" element={<Exit />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<RouteDoesntExist />} />
    </Routes>
  );
};

export default RouteApp;

import styled from "styled-components";

export const PaginationList = styled.ul`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  border: 1px solid #9d9d9d;
  border-radius: 5px;
  margin-right: 2rem;
  cursor: pointer;
  .images-arrow {
    width: 19px;
    height: 19px;
    margin-top: 0.2rem;
  }
`;

export const PaginationListItem = styled.li`
  height: 45px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .disable-page {
    background-color: #c4c4c4 !important;
    opacity: 0.1;
    color: black;
    cursor: not-allowed;
  }
`;

export const ButtonPag = styled.button`
  width: 41px;
  height: 45px;
  margin: 0 0rem;
  font-weight: bold;
  border-left: 1px solid #9d9d9d;
  border-right: 1px solid #9d9d9d;
  color: ${({ theme }) => theme.colors.primary};
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :first-child {
    border-left: none;
  }
  :last-child {
  }
  .arrow-icon {
    width: 16px;
    height: 16px;
  }
`;

export const ButtonPagActive = styled.button`
  width: 41px;
  height: 45px;
  margin: 0 0rem;
  font-weight: bold;
  border-left: 1px solid #9d9d9d;
  border-right: 1px solid #9d9d9d;
  color: ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  :first-child {
    border-left: none;
  }
  :last-child {
  }
`;

import React from "react";
import { Container, SecondaryContainer } from "./styles";

const MasterMain: React.FC = ({ children }) => {
  return (
    <Container>
      <SecondaryContainer>{children}</SecondaryContainer>
    </Container>
  );
};

export default MasterMain;

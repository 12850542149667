import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Exit: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return <></>;
};

export default Exit;

import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.64);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 50px;
  padding: 5px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-size: 24px;
  border: none;
  cursor: pointer;
`;

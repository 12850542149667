import styled from "styled-components";

export const Container = styled.div`
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.gray200};
  overflow-y: scroll;
`;

export const SecondaryContainer = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid #ccc;
  border-radius: 5px;
`;

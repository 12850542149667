import React from "react";

import { CloseButton, ModalContent, ModalOverlay } from "./styles";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewModal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose}>X</CloseButton>
        {children}
      </ModalContent>
    </ModalOverlay>
  );
};

export default NewModal;

import React from "react";
import Zoom from "@mui/material/Zoom";
import { styled } from "@mui/material/styles";
import TooltipMui, {
  TooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";

interface InputProps {
  title: string;
  children: any;
  maxWidth?: number;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

const Tooltip: React.FC<InputProps> = ({
  children,
  title,
  placement,
  maxWidth,
}) => {
  const CustomTooltipMui = styled(({ className, ...props }: TooltipProps) => (
    <TooltipMui {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: maxWidth ? maxWidth : 100,
      fontSize: 12,
      textAlign: "center",
      fontWeight: 500,
      backgroundColor: "#000",
      boxShadow: `0px 0px 5px 0px #775797`,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#000",
    },
  });

  return (
    <CustomTooltipMui
      title={title}
      placement={placement}
      arrow
      TransitionComponent={Zoom}
      enterDelay={1000}
    >
      {children}
    </CustomTooltipMui>
  );
};

export default Tooltip;

import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../hooks/auth";
import { useLocation } from "react-router-dom";

import IconSvg from "../../../../../shared/components/IconSvg";
import ButtonNavigator from "../ButtonNavigator";
import DropdownMenu from "../DropdownMenu";
import ButtonDropdown from "../ButtonDropdown";

import logo from "../../../../../shared/assets/logo.svg";
import homeIcon from "../../../../../shared/assets/icon-home.svg";
import homeHoverIcon from "../../../../../shared/assets/icon-home-hover.svg";
import userIcon from "../../../../../shared/assets/icon-user.svg";
import userHoverIcon from "../../../../../shared/assets/icon-user-hover.svg";
import exitIcon from "../../../../../shared/assets/icon-exit.svg";
import exitHoverIcon from "../../../../../shared/assets/icon-exit-hover.svg";
import pdfIcon from "../../../../../shared/assets/pdf-icon.svg";
import pdfHoverIcon from "../../../../../shared/assets/pdf-icon-hover.svg";

import { AsideControlsContent, Box, ContainerItems } from "./styles";

const MasterAsideControls: React.FC = () => {
  const [isExpandedUser, setIsExpandedUser] = useState(false);

  const { signOut } = useAuth();

  const location = useLocation();

  useEffect(() => {
    (() => {
      if (
        location.pathname === "/users-common" ||
        location.pathname === "/users-admin"
      ) {
        setIsExpandedUser(true);
      } else {
        setIsExpandedUser(false);
      }
    })();
  }, [location.pathname]);

  return (
    <AsideControlsContent>
      <Box>
        <IconSvg image={logo} width="160px" height="33px" />
      </Box>

      <ContainerItems>
        <ButtonNavigator
          icon={homeIcon}
          iconClick={homeHoverIcon}
          name="Início"
          url="/"
        />

        <DropdownMenu
          name="Usuários"
          icon={userIcon}
          iconClick={userHoverIcon}
          expanded={isExpandedUser}
        >
          <ButtonDropdown name="Comuns" url="/users-common" />
          <ButtonDropdown name="Admin" url="/users-admin" />
        </DropdownMenu>

        <ButtonNavigator
          icon={pdfIcon}
          iconClick={pdfHoverIcon}
          name="Ouvidoria"
          url="/support"
        />

        <ButtonNavigator
          icon={exitIcon}
          iconClick={exitHoverIcon}
          name="Sair"
          url="/exit"
          onClick={() => signOut()}
        />
      </ContainerItems>
    </AsideControlsContent>
  );
};

export default MasterAsideControls;

import styled from "styled-components";

export const Container = styled.button`
  padding: 9px 20px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  transition: all 150ms linear;

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

import styled from "styled-components";
import * as Dialog from "@radix-ui/react-dialog";
import { lighten, darken } from "polished";

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 21;
`;

export const Content = styled.div`
  min-width: 30vw;
  border-radius: 6px;
  padding: 20px 50px;
  background: ${({ theme }) => theme.colors.white};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-38%, -50%);
  z-index: 22;

  @media (max-width: 450px) {
    min-width: 80vw;
    padding: 20px 20px;
    left: 40%;
  }
`;

export const Title = styled(Dialog.Title)`
  margin-bottom: 15px;
`;

export const Subtitle = styled.span`
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const SubtitleBold = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 20px;
`;

export const TextInfo = styled.span`
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 20px;
`;

export const TextInputError = styled.span`
  display: block;
  font-size: 1.2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.red};
`;

export const HighlightedInfoText = styled.span`
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const CloseButton = styled(Dialog.Close)`
  position: absolute;
  background: transparent;
  border: 0;
  top: 1.5rem;
  right: 2rem;
  cursor: pointer;
`;

export const CloseButtonOut = styled(Dialog.Close)`
  width: 100%;
  max-width: 200px;
  padding: 10px 20px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  transition: all 150ms linear;
  margin-left: 10px;

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const ConfirmButtonClose = styled(Dialog.Close)`
  width: 100%;
  max-width: 200px;
  padding: 9px 20px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  transition: all 150ms linear;

  &:hover {
    background: ${({ theme }) => darken(0.15, theme.colors.primary)};
  }
`;

export const ConfirmButton = styled.button`
  width: 100%;
  max-width: 200px;
  padding: 9px 20px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  transition: all 150ms linear;

  &:hover {
    background: ${({ theme }) => darken(0.15, theme.colors.primary)};
  }
`;

export const HorizontalStack = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 25px;
`;

export const InputText = styled.input`
  width: 100%;
  height: 40;
  padding: 10px 13px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray300};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray300};
  }

  :focus {
    border-color: ${({ theme }) => lighten(0.4, theme.colors.primary)};
    box-shadow: 0px 0px 2px 0.5px
      ${({ theme }) => lighten(0.4, theme.colors.primary)};
  }
`;

export const ContainerTip = styled.div`
  width: 98%;
  background-color: ${({ theme }) => lighten(0.39, theme.colors.red)};
  border: 1px solid ${({ theme }) => lighten(0.1, theme.colors.red)};
  color: ${({ theme }) => lighten(0.1, theme.colors.red)};
  font-weight: 600;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: -10px;
  margin-bottom: 10px;
`;

import { HTMLProps } from "react";
import styled from "styled-components";
import { lighten } from "polished";

interface ButtonNavProps extends HTMLProps<HTMLButtonElement> {
  isActive?: boolean;
  index?: number;
  indexMenu?: number;
}

export const Container = styled.div`
  width: 100%;

  .active-link {
    display: flex;
    text-decoration: none;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: flex-start;
    margin-left: 20px;

    button span {
      color: ${({ theme }) => lighten(0.3, theme.colors.primary)};
    }
  }

  .inactive-link {
    color: white;
    display: flex;
    background: transparent;
    text-decoration: none;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.8rem;
    padding-left: 2rem;

    button span {
      color: ${({ theme }) => theme.colors.gray};
    }

    /* &:hover {
      background: #252626;
    } */
  }
`;

export const ButtonNav = styled.button<ButtonNavProps>`
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  padding-left: 40px;
  background: ${({ isActive, index, indexMenu }) =>
    isActive && index === indexMenu ? "transparent" : "transparent"};
`;

export const TextNav = styled.span`
  font-size: 14px;
  line-height: 21px;
  margin-left: 33px;
  color: ${({ theme }) => theme.colors.gray};
  font-weight: 600;
`;

import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 9vh 91vh;
  width: 100vw;
  height: 100vh;

  grid-template-areas:
    "aside header"
    "aside main";
`;

import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  transition: all 150ms linear;

  &:hover {
    background: ${({ theme }) => darken(0.1, theme.colors.primary)};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.gray};
    cursor: not-allowed;
  }
`;

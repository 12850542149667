import styled from "styled-components";
import { Avatar } from "@material-ui/core";

export const Header = styled.div`
  grid-area: header;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray200};
`;

export const NavLeft = styled.div`
  display: flex;
  max-width: 400px;
  padding-left: 1.5rem;
  height: 100%;
  align-items: center;
`;

export const NavRight = styled.div`
  display: flex;
  max-width: 100px;
  height: 100%;
  align-items: center;
`;

export const TextHeader = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
  margin-left: 19px;

  .blue-link {
    color: ${({ theme }) => theme.colors.blue};
    text-decoration: underline;
    font-weight: 600;
  }
`;

export const AvatarEditor = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  width: 50px;
  height: 50px;
  margin-right: 52px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
`;

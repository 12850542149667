import styled from "styled-components";
import { darken, lighten } from "polished";

interface ContainerProps {
  isExpanded: boolean;
}

export const Container = styled.div<ContainerProps>`
  .header-collapse {
    display: flex;
    width: 100%;
    height: 60px;
    background: ${({ isExpanded }) => (isExpanded ? "#111111" : "")};
    align-items: center;
    padding-left: 1.5rem;
    justify-content: space-between;
    padding-right: 3rem;

    .content-collapse {
      width: 100%;
      height: 300px;
      background-color: black;
    }

    &:hover {
      background: ${({ isExpanded }) => (isExpanded ? "#111111" : "#252626")};
    }
  }
`;

export const CollapseList = styled.ul`
  width: 100%;
  background: ${darken(0.12, "#343A40")};

  .activeLink {
    width: 100%;
    height: 100%;
    color: red;
  }

  span {
    color: black;
    color: #ccc;
    font-size: 1.4rem;
    margin-left: 1rem;
  }
`;

export const FlexDiv = styled.div`
  width: 100%;
  padding-left: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const TitleDisabled = styled.span`
  font-size: 14px;
  line-height: 21px;
  margin-left: 33px;
  color: ${({ theme }) => theme.colors.gray};
  font-weight: 600;
`;

export const TitleActive = styled.span`
  font-size: 14px;
  line-height: 21px;
  margin-left: 33px;
  color: ${({ theme }) => lighten(0.3, theme.colors.primary)};
  font-weight: 600;
`;

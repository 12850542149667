import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  background: white;
  flex-direction: column;
  padding: 10px;
`;

export const Form = styled.form`
  padding: 0px;
`;

export const ContainerInput = styled.div`
  & + & {
    margin-top: 15px;
  }
`;

export const LabelInput = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;

export const Title = styled.h3`
  font-size: 17px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;

export const ErrorInput = styled.span`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.red};
`;

export const ContainerCheckbox = styled.div`
  width: 100%;
  height: auto;
`;

import React from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../../../../hooks/auth";

import Tooltip from "../../../../../shared/components/Tooltip";

import { AvatarEditor, Header, NavLeft, NavRight, TextHeader } from "./styles";

interface IHeaderMasterProps {
  namePage: string;
}

const HeaderMaster: React.FC<IHeaderMasterProps> = ({ namePage }) => {
  const { data: dataUseAuth } = useAuth();

  const firstName = dataUseAuth.people.first_name.substring(0, 1).toUpperCase();
  const lastName = dataUseAuth.people.last_name.substring(0, 1).toUpperCase();

  return (
    <Header>
      <NavLeft>
        <TextHeader>
          <NavLink to="/" className="blue-link">
            Início
          </NavLink>{" "}
          / {namePage}
        </TextHeader>
      </NavLeft>
      <NavRight>
        <Tooltip
          title={`${dataUseAuth.people.first_name} ${dataUseAuth.people.last_name}`}
        >
          <AvatarEditor>{`${firstName}${lastName}`}</AvatarEditor>
        </Tooltip>
      </NavRight>
    </Header>
  );
};

export default HeaderMaster;

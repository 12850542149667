import styled from "styled-components";

interface IProps {
  src?: string;
}

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.white};
`;

export const LoginBox = styled.form`
  width: 40%;
  height: 100%;
  min-width: 768px;
  display: flex;
  padding: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImageBox = styled.div<IProps>`
  width: 60%;
  height: 100%;
  background-image: url(${({ src }) => src && src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Heading = styled.h1`
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
`;

export const BoxLogo = styled.div`
  width: 174px;
  height: 77px;
  margin: 0 auto;
  overflow: hidden;

  img {
    transform: translateX(-2px) translateY(-1px);
  }
`;

export const ContainerInput = styled.div`
  width: 60%;

  & + & {
    margin-top: 15px;
  }
`;

export const ErrorInput = styled.span`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.red};
`;

import styled from "styled-components";
import { lighten } from "polished";

export const AsideControlsContent = styled.aside`
  grid-area: aside;
  background: ${({ theme }) => theme.colors.grayDark};
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 45px 0;
`;

export const Box = styled.div`
  width: 100%;
  margin-bottom: 49px;
  display: flex;
  margin-left: 30px;
`;

export const ContainerItems = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;

  // Firefox
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.colors.grayDark} transparent;

  //Chrome, Edge, and Safari
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.grayDark};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => lighten(0.25, theme.colors.grayDark)};
    border-radius: 50px;
    border: 2px solid ${({ theme }) => theme.colors.grayDark};
  }
`;

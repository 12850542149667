import { toast } from "react-toastify";

interface IRequest {
  text: string;
  type: "success" | "error" | "info";
}

function Notify({ text, type }: IRequest) {
  if (type === "success") {
    toast.success(text);
  } else if (type === "error") {
    toast.error(text);
  } else if (type === "info") {
    toast.info(text);
  }
}

export { Notify };

import React from "react";
import {
  ButtonPag,
  ButtonPagActive,
  PaginationList,
  PaginationListItem,
} from "./styles";

import arrowLeft from "../../assets/arrow-left.svg";
import arrowRight from "../../assets/arrow-right.svg";
import arrowLeftActive from "../../assets/arrow-left-active.svg";
import arrowRightActive from "../../assets/arrow-right-active.svg";

interface QueryProps {
  search?: string;
  filter?: string;
  pagination?: number;
}

interface PaginationProps {
  total: number;
  offset: number;
  setDataParams: React.Dispatch<React.SetStateAction<QueryProps>>;
  dataParams: QueryProps;
}

const Pagination: React.FC<PaginationProps> = ({
  total,
  offset,
  setDataParams,
  dataParams,
}) => {
  const MAX_ITEMS = 15;
  const MAX_LEFT = Number(((MAX_ITEMS + 1) / 2).toFixed(0));
  const currentPage = offset + 1;
  const pages = total + 1;
  const first = Math.max(currentPage - MAX_LEFT, 1);

  const onPageChange = (page: number) => {
    setDataParams({ ...dataParams, pagination: page - 1 });
  };

  return (
    <>
      {pages > 0 && (
        <PaginationList>
          <ButtonPag
            disabled={currentPage === 1}
            onClick={() => onPageChange(currentPage - 1)}
          >
            {currentPage === 1 ? (
              <img src={arrowLeft} className="arrow-icon" alt="" />
            ) : (
              <img src={arrowLeftActive} className="arrow-icon" alt="" />
            )}
          </ButtonPag>
          {Array.from({ length: Math.min(MAX_ITEMS, pages) })
            .map((_, index) => index + first)
            .map((page) => (
              <PaginationListItem key={String(Math.random())}>
                {page === currentPage ? (
                  <ButtonPagActive
                    onClick={() => onPageChange(page)}
                    className={"active-button"}
                  >
                    {page}
                  </ButtonPagActive>
                ) : page > total + 1 ? (
                  <></>
                ) : (
                  <ButtonPag
                    onClick={() =>
                      setDataParams({ ...dataParams, pagination: page - 1 })
                    }
                    className={"active-button"}
                  >
                    {page}
                  </ButtonPag>
                )}
              </PaginationListItem>
            ))}
          <ButtonPag
            onClick={() => onPageChange(currentPage + 1)}
            style={{ borderRight: "none", borderLeft: "none" }}
            disabled={currentPage === total + 1}
          >
            {currentPage === total + 1 ? (
              <img className="arrow-icon" src={arrowRight} alt="" />
            ) : (
              <img className="arrow-icon" src={arrowRightActive} alt="" />
            )}
          </ButtonPag>
        </PaginationList>
      )}
    </>
  );
};

export default Pagination;

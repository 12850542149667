import React, { useCallback, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { X } from "phosphor-react";

import {
  Content,
  Overlay,
  CloseButton,
  HorizontalStack,
  CloseButtonOut,
  ConfirmButton,
  Title,
  Subtitle,
  SubtitleBold,
  TextInfo,
  HighlightedInfoText,
  TextInputError,
  ConfirmButtonClose,
  InputText,
  ContainerTip,
} from "./styles";

interface ModalProps {
  title: string;
  subtitle: string;
  type: "block" | "delete" | "subscription";
  isBlocked?: boolean;
  callback: () => void;
  isTip?: boolean;
}

const NewModal: React.FC<ModalProps> = ({
  title,
  subtitle,
  callback,
  type,
  isBlocked,
  isTip,
}) => {
  const [inputError, setInputError] = useState(false);
  const [closeButton, setCloseButton] = useState(false);

  let block = false;
  if (isBlocked) block = isBlocked;

  let tip = false;
  if (isTip) tip = isTip;

  const handleChangeInput = useCallback(async (event: string) => {
    try {
      if (event === "confirmar") {
        setInputError(false);
        setCloseButton(true);
      } else {
        setInputError(true);
        setCloseButton(false);
      }
    } catch (err: any) {
      console.log(err?.response?.data);
    }
  }, []);

  const hendleCallback = useCallback(() => {
    setInputError(false);
    setCloseButton(false);
    callback();
  }, [callback]);

  return (
    <Dialog.Portal>
      <Overlay />
      <Content>
        <Title>{title}</Title>

        <CloseButton
          onClick={() => {
            setInputError(false);
            setCloseButton(false);
          }}
        >
          <X size={24} />
        </CloseButton>

        {type === "delete" && (
          <Subtitle className="text">
            Você realmente quer deletar: <SubtitleBold>{subtitle}</SubtitleBold>
          </Subtitle>
        )}

        {type === "block" && (
          <Subtitle className="text">
            Você realmente quer {block ? "desbloquear" : "bloquear"}:{" "}
            <SubtitleBold>{subtitle}</SubtitleBold>
          </Subtitle>
        )}

        {type === "subscription" && (
          <Subtitle className="text">
            Você realmente quer {block ? "desativar" : "ativar"} assinatura de:{" "}
            <SubtitleBold>{subtitle}</SubtitleBold>
          </Subtitle>
        )}

        {tip && (
          <ContainerTip>
            Todos os dados do usuário será excluido de forma permanente do UX
            Doc e NÃO será possível recupear.
          </ContainerTip>
        )}

        <TextInfo>
          Para você completar essa ação, você precisa escrever a palavra{" "}
          <HighlightedInfoText>"confirmar"</HighlightedInfoText> dentro da caixa
          de texto
        </TextInfo>

        <InputText
          placeholder="escreva: confirmar"
          style={{ width: "98%", borderColor: inputError ? "red" : "" }}
          onChange={(event) => handleChangeInput(event.currentTarget.value)}
        />
        {inputError && (
          <TextInputError>
            você precisa digitar corretamente a palavra: confirmar
          </TextInputError>
        )}

        <HorizontalStack>
          {!closeButton && (
            <ConfirmButton onClick={() => setInputError(true)}>
              Sim
            </ConfirmButton>
          )}

          {closeButton && (
            <ConfirmButtonClose onClick={() => hendleCallback()}>
              Sim
            </ConfirmButtonClose>
          )}

          <CloseButtonOut
            onClick={() => {
              setInputError(false);
              setCloseButton(false);
            }}
          >
            Não
          </CloseButtonOut>
        </HorizontalStack>
      </Content>
    </Dialog.Portal>
  );
};

export default NewModal;

import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../../../../services/api";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SyncLoader from "react-spinners/SyncLoader";
import * as Dialog from "@radix-ui/react-dialog";
import { format } from "date-fns";
import { useAuth } from "../../../../../../hooks/auth";
import { saveAs } from "file-saver";

import IHookForm from "../../../../../../shared/dtos/IHookForm";
import IListUsersCommonApiProps from "./dtos/IListUsersCommonApiProps";
import IListUsersCommonDataParams from "./dtos/IListUsersCommonDataParams";
import IFormSearch from "./dtos/IFormSearch";

import MasterAsideControls from "../../../../components/SidebarMaster/MasterAsideControls";
import HeaderMaster from "../../../../components/SidebarMaster/HeaderMaster";
import MasterContainer from "../../../../components/SidebarMaster/MasterContainer";
import MasterMain from "../../../../components/SidebarMaster/MasterMain";

import DialogModal from "../../../../../../shared/components/DialogModal";
import Modal from "../../../../../../shared/components/Modal";
import Button from "../../../../../../shared/components/Button";
import Input from "../../../../../../shared/components/Input";
import IconSvg from "../../../../../../shared/components/IconSvg";
import Pagination from "../../../../../../shared/components/Pagination";
import Tooltip from "../../../../../../shared/components/Tooltip";

import trashIcon from "../../../../../../shared/assets/trash-icon.svg";
import iconSearchWhite from "../../../../../../shared/assets/icon-search-white.svg";
import iconPasswordPassword from "../../../../../../shared/assets/reset-password.svg";

import { Notify } from "../../../../../../shared/utils";

import {
  BoxList,
  ButtonFlexBox,
  ButtonSearch,
  FlexBox,
  HStack,
  Main,
  Form,
  ContainerCreate,
  FlexPagination,
  ContainerLoading,
  Table,
  TableTh,
  TableTr,
  TableTd,
  TableThead,
  TableTbody,
  TableTdText,
  FileUploadContainer,
  FileUploadInput,
  FileUploadLabel,
  TitleModal,
  SubtitleModal,
  ContainerLoadingModal,
} from "./styles";

const ListUsersCommon: React.FC = () => {
  const [modal, setModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [dataUsersCommonList, setDataUsersCommonList] =
    useState<IListUsersCommonApiProps>({
      pagination: 0,
      dataArray: [],
    });
  const [listUsersCommonDataParams, setListUsersCommonDataParams] =
    useState<IListUsersCommonDataParams>({
      pagination: 0,
      is_admin: false,
    });

  const { data: dataUseAuth } = useAuth();

  const navigate = useNavigate();

  const handleCreateUsersCommon = useCallback(() => {
    navigate("/create-user-common");
  }, [navigate]);

  useEffect(() => {
    (async () => {
      const response = await api.get(
        `/organization-users/${dataUseAuth.organization.id}`,
        {
          params: listUsersCommonDataParams,
        }
      );

      setDataUsersCommonList(response.data);
      setTotalPages(response.data.pagination);
      setLoading(false);
    })();
  }, [reload, listUsersCommonDataParams, dataUseAuth]);

  const handleDeleteUsersCommon = useCallback(
    async (id: string) => {
      await api.delete(`/organization-users/${id}`);

      Notify({ text: "Usuário deletado com sucesso!", type: "success" });

      setReload(!reload);
    },
    [reload]
  );

  const formSchema = yup.object().shape({
    search: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IHookForm>({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<IFormSearch> = useCallback(async (formData) => {
    try {
      setLoading(true);
      if (formData.search === "") {
        setListUsersCommonDataParams({
          pagination: 0,
          is_admin: false,
        });
      } else {
        setListUsersCommonDataParams({
          pagination: 0,
          search: formData.search,
          is_admin: false,
        });
      }
    } catch (err: any) {
      console.log(err?.response?.data);
    }
  }, []);

  const handleDownloadExcel = useCallback(() => {
    const fileUrl =
      "https://vinverai.s3.amazonaws.com/cadastro_de_usuario.xlsx";

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, "cadastro_de_usuario.xlsx");
      })
      .catch((error) => {
        console.error("Erro ao baixar o arquivo:", error);
      });
  }, []);

  const handleCloseModal = useCallback(() => {
    setModal(false);
    setSelectedFile(null);
  }, []);

  const handleOpenModal = useCallback(() => {
    setModal(true);
  }, []);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      if (file) {
        setSelectedFile(file);
      }
    },
    []
  );

  const handleUpload = useCallback(async () => {
    if (selectedFile) {
      try {
        setLoadingModal(true);

        const patch = new FormData();

        patch.append("excel", selectedFile);

        const query = `is_admin=false`;

        const uploadShape = await api.patch(
          `organization-users/excel-upload/${dataUseAuth.organization.id}?${query}`,
          patch,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (uploadShape.status === 200) {
          Notify({
            text: "Usuário cadastrados com sucesso utilizando o excel!",
            type: "success",
          });
          setLoadingModal(false);
          handleCloseModal();
          setReload(!reload);
        }
      } catch (error: any) {
        setLoadingModal(false);
        Notify({
          text: "Aconteceu algum erro ao cadastrar os usuários utilizando o excel.",
          type: "error",
        });
      }
    }
  }, [handleCloseModal, selectedFile, dataUseAuth.organization.id, reload]);

  return (
    <MasterContainer>
      <MasterAsideControls />
      <HeaderMaster namePage="Listar usuários" />
      <MasterMain>
        <Main>
          <ContainerCreate>
            <Button onClick={handleCreateUsersCommon}>Criar usuário</Button>

            <Button style={{ marginLeft: 15 }} onClick={handleOpenModal}>
              Cadastrar com Excel
            </Button>
          </ContainerCreate>
          <HStack>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Input
                label="search"
                register={register}
                required={true}
                placeholder="Pesquisar"
                style={{ width: "98%" }}
              />

              <Tooltip title="Pesquisar" placement="top">
                <ButtonSearch type="submit">
                  <IconSvg image={iconSearchWhite} width="18" height="18" />
                </ButtonSearch>
              </Tooltip>
            </Form>
          </HStack>

          <BoxList>
            {loading && (
              <ContainerLoading>
                <SyncLoader loading={loading} color="#15aabf" size={10} />
              </ContainerLoading>
            )}

            {!loading && (
              <Table>
                <TableThead>
                  <TableTr>
                    <TableTh>Nome</TableTh>
                    <TableTh>Sobrenome</TableTh>
                    <TableTh>E-mail</TableTh>
                    <TableTh>Tipo do usuário</TableTh>
                    <TableTh>Criado em</TableTh>
                    <TableTh>Opções</TableTh>
                  </TableTr>
                </TableThead>

                <TableTbody>
                  {dataUsersCommonList.dataArray.map((item, index) => (
                    <TableTr key={item.id}>
                      <TableTd>
                        <TableTdText>{item.first_name}</TableTdText>
                      </TableTd>
                      <TableTd>
                        <TableTdText>{item.last_name}</TableTdText>
                      </TableTd>
                      <TableTd>
                        <TableTdText>{item.email}</TableTdText>
                      </TableTd>
                      <TableTd>
                        <TableTdText>{item.user_type}</TableTdText>
                      </TableTd>
                      <TableTd>
                        <TableTdText>
                          {format(
                            new Date(item.created_at),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </TableTdText>
                      </TableTd>

                      <TableTd>
                        <FlexBox>
                          <Tooltip title="Atualizar senha" placement="top">
                            <ButtonFlexBox
                              onClick={() => {
                                navigate(
                                  `/reset-password-user-common/${item.user_id}`
                                );
                              }}
                            >
                              <IconSvg
                                width="18"
                                height="18"
                                image={iconPasswordPassword}
                              />
                            </ButtonFlexBox>
                          </Tooltip>

                          <Dialog.Root>
                            <Tooltip title="Deletar usuário" placement="top">
                              <Dialog.Trigger asChild>
                                <ButtonFlexBox>
                                  <IconSvg
                                    width="18"
                                    height="18"
                                    image={trashIcon}
                                  />
                                </ButtonFlexBox>
                              </Dialog.Trigger>
                            </Tooltip>

                            <DialogModal
                              title="Deletar usuário"
                              subtitle={`${item.first_name} ${item.last_name}`}
                              type="delete"
                              callback={() =>
                                handleDeleteUsersCommon(item.user_id)
                              }
                            />
                          </Dialog.Root>
                        </FlexBox>
                      </TableTd>
                    </TableTr>
                  ))}
                </TableTbody>
              </Table>
            )}
          </BoxList>
          <FlexPagination>
            {dataUsersCommonList.dataArray.length > 0 && (
              <Pagination
                total={totalPages}
                offset={listUsersCommonDataParams.pagination}
                setDataParams={setListUsersCommonDataParams}
                dataParams={listUsersCommonDataParams}
              />
            )}
          </FlexPagination>

          <Modal isOpen={modal} onClose={handleCloseModal}>
            <FileUploadContainer>
              {loadingModal && (
                <ContainerLoadingModal>
                  <SyncLoader
                    loading={loadingModal}
                    color="#15aabf"
                    size={10}
                  />
                </ContainerLoadingModal>
              )}

              {!loadingModal && (
                <>
                  <TitleModal>Cadastrar com Excel</TitleModal>

                  <SubtitleModal>
                    Faça upload do seu arquivo Excel no botão abaixo
                  </SubtitleModal>

                  <Button
                    style={{ marginBottom: 20 }}
                    onClick={handleDownloadExcel}
                  >
                    Baixar modelo de excel
                  </Button>

                  <FileUploadInput
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                    id="file-upload"
                  />

                  <FileUploadLabel htmlFor="file-upload">
                    {selectedFile ? selectedFile.name : "Selecionar arquivo"}
                  </FileUploadLabel>

                  {selectedFile && (
                    <Button onClick={handleUpload}>Enviar</Button>
                  )}
                </>
              )}
            </FileUploadContainer>
          </Modal>
        </Main>
      </MasterMain>
    </MasterContainer>
  );
};

export default ListUsersCommon;
